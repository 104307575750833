import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import req from '@http'

export const useFilterStore = defineStore('filterStore', () => {

  let templates = {}
  let locations = {}
  let templateTags = null
  let regionsAndLocations = {}
  let regions = {}
  let users = {}
  let userGroups = null
  let actionSteps = {}
  let actionTypes = {}

  const addMoreParams = (params, moreParams) => {
    for (const key in moreParams) {
      params[key] = moreParams[key]
    }

    return params
  }

  const getLocations = async(moreParams) => {
    const params = addMoreParams({
      unpack_json: 1
    }, moreParams)

    const hash = JSON.stringify(params)
    if (!locations[hash]) {
      locations[hash] = req.get('/api/regions-and-locations', {
        params: params
      })
    }


    const r = await locations[hash]

    return r.data.data.locations
  }

  const getTemplates = async(moreParams) => {
    const params = addMoreParams({
      archived: moreParams?.archived || null,
      with_groups: 1,
      with_locations: 1,
      with_archived_at: 1,
      unpack_json: 1
    }, moreParams)

    const hash = JSON.stringify(params)

    if (!templates[hash]) {
      templates[hash] = req.get('/api/template-list', {
        params: params
      })
    }

    const r = await templates[hash]

    return r.data.data
  }

  const getTemplateTags = async() => {
    if (!templateTags) {
      templateTags = req.get('/api/audit-template-tags')
    }

    const r = await templateTags

    return r.data.data
  }

  const getRegionsAndLocations = async(moreParams) => {
    const params = addMoreParams({
    }, moreParams)

    const hash = JSON.stringify(params)

    if (!regionsAndLocations[hash]) {
      regionsAndLocations[hash] =  req.get('/api/regions-and-locations', {
        params: params
      })
    }

    const r = await regionsAndLocations[hash]

    return r.data.data
  }

  const getRegions = async(moreParams) => {
    const params = addMoreParams({
    }, moreParams)

    const hash = JSON.stringify(params)

    if (!regions[hash]) {
      regions[hash] = req.get('/api/region', {
        params: params
      })
    }

    const r = await regions[hash]

    return r.data.data
  }

  const getUsers = async(moreParams) => {
    const params = addMoreParams({}, moreParams)

    const hash = JSON.stringify(params)
    if (!users[hash]) {
      users[hash] = req.get('/api/users', {
        params: params
      })
    }

    const r = await users[hash]

    return r.data.data
  }

  const getUserGroups = async() => {
    if (!userGroups) {
      userGroups = req.get('/api/user-groups')
    }

    const r = await userGroups

    return r.data.data
  }

  const getActionTypes = async(moreParams) => {
    const params = addMoreParams({}, moreParams)
    params.with_steps = 1
    params.unpack_json = 1
    const hash = JSON.stringify(params)

    if (!actionTypes[hash]) {
      actionTypes[hash] = req.get('/api/manage/action-types', {
        params: params
      })
    }

    const r = await actionTypes[hash]

    return r.data.data
  }

  const getActionSteps = async(moreParams) => {
    const params = addMoreParams({}, moreParams)
    const hash = JSON.stringify(params)

    if (!actionSteps[hash]) {
      actionSteps[hash] = req.get('/api/action-steps', {
        params: params
      })
    }

    const r = await actionSteps[hash]

    return r.data.data
  }

  return {
    getTemplates,
    getLocations,
    getTemplateTags,
    getRegionsAndLocations,
    getRegions,
    getUsers,
    getUserGroups,
    getActionTypes,
    getActionSteps
  }

})
